<template>
  <v-container style="background:white">
    <v-tabs
      slot="extension"
      v-model="tabs"
      color="secondary"
    >
      <v-tab
        v-for="tabsItem in tabsItems"
        :key="tabsItem.id"
        :to="tabsItem.link"
      >
        <span class="pl-2 pr-2">{{ tabsItem.title }}</span>
      </v-tab>
    </v-tabs>
    <v-container>
      <v-row>
        <router-view />
      </v-row>
    </v-container>
    <div class="py-3" />
  </v-container>
</template>

<script>
  const fb = require('../firebaseConfig.js')
  export default {
    name: 'QDetail',
    props: {
      asuulgaId: {
        type: String,
        required: false,
      },
    },
    data () {
      return {
        tabs: null,
        tabsItems: [
          { id: 1, title: 'Бүртгэл', link: '/qdetail/' + this.asuulgaId + '/zparticipants' },
          { id: 2, title: 'Асуултууд', link: '/qdetail/' + this.asuulgaId + '/qquestionediting' },
          { id: 3, title: 'Xариултууд', link: '/qdetail/' + this.asuulgaId + '/zresponses' },

          { id: 4, title: 'Дүн шинжилгээ', link: '/qdetail/' + this.asuulgaId + '/qresponses' },
        ],
      }
    },
    created() {
      console.log(this.asuulgaId)
      // this._findCategories()
      // if (this.categories != null && this.categories.length > 0)
      //             this.selectedCategory = this.categories[0];
    },
    methods: {
      _findCategories() {
        var _this = this
        var asuulga = fb.db.collection('asuulga').doc(this.asuulgaId)
        asuulga
          .get()
          .then(function (doc) {
            if (doc.exists) {
              _this.asuulga = doc.data()
              _this.asuulga.ref = doc.ref
              _this.asuulga.id = doc.id

              if (_this.asuulga.bgDetailColor != null && _this.asuulga.bgDetailColor !== undefined) {
                _this.bgDetailColor = _this.asuulga.bgDetailColor
              }
            } else {
              console.log('No such document!')
            }
          })
          .catch(function (error) {
            console.log('Error getting document:', error)
          })

        fb.db
          .collection('asuulga/' + this.asuulgaId + '/categories')
          .orderBy('createdAt', 'asc')
          .onSnapshot((querySnapshot) => {
            this.categories = []
            querySnapshot.forEach((catDoc) => {
              const cat = catDoc.data()
              cat.id = catDoc.id
              cat.ref = catDoc.ref
              cat.questions = []
              this._getQuestionsOfCategory(cat)
              this.categories.push(cat)
              if (this.categories.length === 1) {
                this.selectedCategory = this.categories[0]
              }
            })
          })
      },
    },
  }
</script>
